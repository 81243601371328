import React from "react"
import { graphql, StaticQuery } from "gatsby"
import { navigate } from "gatsby-link"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Recaptcha from "react-google-recaptcha"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons"
import Swal from "sweetalert2"
import "../utils/normalize.css"
import "../utils/css/screen.css"

const RECAPTCHA_KEY = process.env.SITE_RECAPTCHA_KEY

function encode(data) {
  const formData = new FormData()

  for (const key of Object.keys(data)) {
    formData.append(key, data[key])
  }

  return formData
}

class ContactForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleRecaptcha = value => {
    this.setState({ recaptchaResponse: value })
  }

  handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    if (!this.state.recaptchaResponse) {
      Swal.fire({
        html: '<p class="swal2-content-m3d">Confirma que no eres un robot, antes de enviar el mensaje<p>',
        icon: "warning",
        confirmButtonText: "Ok",
        confirmButtonClass: "customConfirmButtonM3D",

      })
      return;
    }

    const rebody = encode({
      "form-name": form.getAttribute("name"),
      "g-recaptcha-response": this.state.recaptchaResponse,
      ...this.state,
    })

    fetch("/", {
      method: "POST",
      body: rebody,
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch(error => alert(error))
  }

  render() {
    return (
      <form
        name="contact"
        method="POST"
        action="/thanks"
        data-netlify-recaptcha="true"
        data-netlify="true"
        onSubmit={this.handleSubmit}
      >
        <div className="row gtr-uniform">
          <div className="col-12 col-12-xsmall">
            <input
              type="text"
              name="name"
              id="name"
              placeholder="Nombre"
              required
              onChange={this.handleChange}
            />
          </div>
          <div className="col-8 col-12-xsmall">
            <input
              type="email"
              name="email"
              id="email"
              placeholder="Correo electrónico"
              required
              onChange={this.handleChange}
            />
          </div>
          <div className="col-4 col-12-xsmall">
            <input
              type="text"
              name="phone"
              id="phone"
              placeholder="Teléfono"
              onChange={this.handleChange}
            />
          </div>

          <div className="col-12 col-12-xsmall">
            <input
              type="text"
              name="subject"
              id="subject"
              placeholder="Asunto"
              onChange={this.handleChange}
            />
          </div>

          {/* Break */}
          <div className="col-12">
            <textarea
              name="message"
              id="message"
              placeholder="Escriba su mensaje"
              rows={6}
              defaultValue={""}
              required
              onChange={this.handleChange}
            />
          </div>

          <div className="col-12">
            <Recaptcha
              ref="recaptcha"
              sitekey={RECAPTCHA_KEY}
              onChange={this.handleRecaptcha}
            />
          </div>

          <div className="col-12">
            <input
              type="submit"
              defaultValue="Send Message"
              className="primary"
            />
          </div>
        </div>
      </form>
    )
  }
}

const ContactUsPage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout title={siteTitle}>
      <SEO title="Contáctenos" keywords={[]} />

      <article className="post-content-contact page-template no-image">
        <div className="post-content-body">
          <h1>Contáctenos</h1>
          <div className="contact-container">
            <div className="contact-text-info">
              <p>
                Para mayor información, conversar o simplemente llevar a cabo
                algún sueño creativo e innovador déjanos tus datos y nos
                comunicaremos contigo. También puedes contactarnos por medio de
                nuestro correo o número telefónico.
              </p>
              <div className="contact-detail-container">
                <div className="contact-item">
                  <a target="_blank" rel="noopener noreferrer" href="https://wa.me/50685759745?text=Hola%2C%20estoy%20interesado%20en%20conocer%20m%C3%A1s%20de%20su%20servicio%20como%C2%A0arquitecto">
                    <i>
                      <FontAwesomeIcon icon={faWhatsapp} size="1x" />
                    </i>
                    <span>+506 8575-9745</span>
                  </a>
                </div>
              </div>
            </div>
            <div className="contact-form-container">
              <ContactForm />
            </div>
          </div>
        </div>
      </article>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <ContactUsPage location={props.location} data={data} {...props} />
    )}
  />
)
